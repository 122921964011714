<template>
  <div class="home">
    <v-layout row wrap>
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          Dashboard / Agent / {{ account.name }}
        </h1>
      </v-flex>
      <v-flex xs12 md12 lg7 class="pl-2 pr-2">
        <v-simple-table>
          <tbody>
            <tr>
              <th class="fs-12 primary--text">Type</th>
              <th class="fs-12">{{ account.type }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Ref ID</th>
              <th class="fs-12">{{ account.ref_id }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Name</th>
              <th class="fs-12">{{ account.name }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Email</th>
              <th class="fs-12">{{ account.email }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Phone</th>
              <th class="fs-12">{{ account.phone }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Phone Alt.</th>
              <th class="fs-12">{{ account.phone_alt }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Fax</th>
              <th class="fs-12">{{ account.fax }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Address</th>
              <th class="fs-12">{{ account.address }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">City</th>
              <th class="fs-12">{{ account.city }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Country</th>
              <th class="fs-12">{{ account.country }}</th>
            </tr>
            <tr v-if="country == 'United Arab Emirates'">
              <th class="fs-12 primary--text">Emirate</th>
              <th class="fs-12">{{ account.emirate }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">VAT</th>
              <th class="fs-12">{{ account.vat }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">TRN</th>
              <th class="fs-12">{{ account.trn }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Account Added On</th>
              <th class="fs-12">
                {{ account.created | moment("dddd, MMMM Do YYYY") }}
              </th>
            </tr>
          </tbody>
        </v-simple-table>
      </v-flex>
      <v-flex xs12 md12 lg5 class="pl-2 pr-2">
        <v-simple-table>
          <tbody>
            <tr>
              <th class="fs-12 primary--text">Credit Limit</th>
              <th class="fs-12">{{ account.credit }} AED</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Commision</th>
              <th class="fs-12">{{ account.commission }} %</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Payment Interval</th>
              <th class="fs-12">{{ account.payment_interval }} DAYS</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Last Payment Amount</th>
              <th class="fs-12"><b>{{ account.lastPaymentAmount }} AED</b></th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Last Payment Date</th>
              <th class="fs-12"><b>{{ account.lastPaymentDate | moment("dddd, MMMM Do YYYY")}}</b></th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Payment Due</th>
              <th class="fs-12"><b>{{ account.nextPaymentAmount }} AED</b></th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Payment Due On</th>
              <th class="fs-12"><b>{{ account.nextPaymentDate | moment("dddd, MMMM Do YYYY")}}</b> </th>
            </tr>
          </tbody>
        </v-simple-table>
        <v-simple-table class="mt-5">
          <tbody>
            <tr>
              <th class="fs-12 primary--text">Options</th>
              <th class="fs-12">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="black--text"
                      light
                      block
                      v-bind="attrs"
                      v-on="on"
                    >
                      Manage Account <span><v-icon>mdi-menu-down</v-icon></span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>Edit Account</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title @click="dialog = true"
                        >Set Password</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Block Account</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Delete Account</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </th>
            </tr>
          </tbody>
        </v-simple-table>
      </v-flex>
      
    </v-layout>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 primary--text grey darken-3">
          Set Agent Password
        </v-card-title>
        <v-card-text class="pt-5 pb-10">
          <v-text-field
            single-line
            outlined
            v-model="password"
            label="Password"
            class="mt-5"
          ></v-text-field>
          <v-text-field
            single-line
            outlined
            v-model="confirmPassword"
            label="Confirm Password"
            class="mt-5"
          ></v-text-field>
          <v-btn @click="setPassword" class="primary black--text mt-5" x-large
            >SET PASSWORD</v-btn
          >
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 primary--text grey darken-3">
          Password Changed
        </v-card-title>
        <v-card-text>
          <p class="mt-5">
            Please save or download the password for this account. You will not
            be able to view the view this password again.
          </p>
          <div
            class="pa-3"
            style="
              border: 1px solid #424242;
              border-radius: 5px;
              margin-top: 10px;
            "
          >
            <p class="accent--text">{{ password }}</p>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="copy"> Copy </v-btn>
          <v-btn color="primary" text @click="downloadCSV"> Download </v-btn>
          <v-btn color="primary" text @click="dialog2 = false"> CLOSE </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { BASE_URL } from "@/config";
import Axios from "axios";

export default {
  data() {
    return {
      account: [],
      dialog: false,
      dialog2: false,
      password: "",
      confirmPassword: "",
    };
  },
  mounted() {
    this.fetchAccount();
  },
  methods: {
    downloadCSV() {
      const userData = [{
        name : this.account.name,
        email : this.account.email,
        password : this.password
      }]
      const csvContent = this.convertToCSV(userData);
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = this.account.name+'.csv';
      link.click();
    },
    convertToCSV(data) {
      const header = Object.keys(data[0]).join(',');
      const rows = data.map(obj => Object.values(obj).join(',')).join('\n');
      return `${header}\n${rows}`;
    },
    async fetchAccount() {
      let url = BASE_URL + "/agent/" + this.$route.params.id;
      let { data } = await Axios.get(url);
      this.account = data;
    },
    edit() {
      this.$router.push({
        name: "EditAccount",
        params: { id: this.$route.params.id },
      });
    },
    async setPassword() {
      if (this.password == this.confirmPassword) {
        let url = BASE_URL + "/agent/password/" + this.$route.params.id;
        let payload = {
          user: this.account._id,
          password: this.password,
        };
        let { data } = await Axios.patch(url, payload);
        this.dialog = false;
        this.dialog2 = true;
      } else {
      }
    },
  },
};
</script>
  
    <style scoped>
.fs-12 {
  font-size: 12pt !important;
  font-weight: 400 !important;
}
</style>